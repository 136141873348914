import { Tag } from 'antd';
import dayjs from 'dayjs';
import {
  QueryTableFilterISchemaProperties,
  QueryTableColumnProps,
} from 'src/components/QueryTable';
import { gradeTimeItems } from 'src/constants/exam';

export const schema: QueryTableFilterISchemaProperties = {
  examName: {
    type: 'string',
    title: '考试名称',
    'x-component': 'Input',
    'x-component-props': {
      placeholder: '请输入考试名称',
    },
    'x-decorator': 'FormItem',
  },
  count: {
    type: 'number',
    title: '考试次数',
    'x-component': 'NumberPicker',
    'x-component-props': {
      min: 1,
      addonAfter: '次',
      precision: 0,
      step: 1,
      style: {
        width: '100%',
      },
      placeholder: '请输入考试次数',
    },
    'x-decorator': 'FormItem',
  },
  gradePublishTime: {
    type: 'array',
    title: '成绩公布时间',
    'x-component': 'DatePicker',
    'x-component-props': {
      showTime: true,
      placeholde: ['请选择成绩公布时间'],
    },
    'x-decorator': 'FormItem',
  },
  '[startTime, endTime]': {
    type: 'array',
    title: '考试时间',
    'x-component': 'DatePicker.RangePicker',
    'x-component-props': {
      showTime: true,
      minDate: dayjs(),
      placeholde: ['请选择考试开始时间', '请选择考试结束时间'],
    },
    'x-decorator': 'FormItem',
  },
};

export const columns: QueryTableColumnProps = [
  {
    title: '考试名称',
    key: 'examName',
    dataIndex: 'examName',
    width: 100,
  },
  {
    title: '考试次数',
    key: 'count',
    dataIndex: 'count',
    width: 80,
  },
  {
    title: '成绩公布类型',
    key: 'gradePublishType',
    dataIndex: 'gradePublishType',
    width: 80,
    render: (type: number) => {
      return gradeTimeItems[type]?.label ?? '--';
    },
  },
  {
    title: '答案公布时间',
    key: 'gradePublishTime',
    dataIndex: 'gradePublishTime',
    width: 120,
    render: (time: string) => {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '开启视频监控',
    key: 'videoMonitor',
    dataIndex: 'videoMonitor',
    width: 120,
    render: (type: boolean) => {
      return <Tag color="geekblue">{type ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '抓拍次数',
    key: 'videoMonitorCount',
    dataIndex: 'videoMonitorCount',
    width: 120,
    render: (count: number) => {
      return `${count}次`;
    },
  },
  {
    title: '开启切屏监控',
    key: 'screenMonitor',
    dataIndex: 'screenMonitor',
    width: 120,
    render: (type: boolean) => {
      return <Tag color="geekblue">{type ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '切屏次数',
    key: 'screenMonitorCount',
    dataIndex: 'screenMonitorCount',
    width: 120,
    render: (count: number) => {
      return `${count}次`;
    },
  },
  {
    title: '及格分数',
    key: 'passScore',
    dataIndex: 'passScore',
    width: 120,
    render: (score: number) => {
      return `${score}分`;
    },
  },
  {
    title: '考试开始时间',
    key: 'startTime',
    dataIndex: 'startTime',
    width: 120,
    render: (time: string) => {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '考试结束时间',
    key: 'endTime',
    dataIndex: 'endTime',
    width: 120,
    render: (time: string) => {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

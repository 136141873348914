import {} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { useMemoizedFn } from 'ahooks';
import { QueryTable } from 'src/components/QueryTable';
import { getExamList } from 'src/api/clients';
import { Input, DatePicker, NumberPicker } from '@formily/antd-v5';
import { schema, columns } from './schema';
import { IExamInfo, IQueryExamination } from 'src/api/types/exam';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const QueryTableExamnationList = 'QueryTableExamnationList';

export default function ExaminationList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goInfoPage = useMemoizedFn(() => {
    navigate('/exam/examination/info');
  });

  const requestFn = useMemoizedFn(async (params: IQueryExamination) => {
    const result = await getExamList(params);
    return {
      data: result?.rows,
      total: result?.count,
    };
  });

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('exam.examination.list')}</div>
      <QueryTable.Provider useQuery={false} name={QueryTableExamnationList} requestFn={requestFn}>
        <QueryTable.Filter schema={schema} components={{ Input, DatePicker, NumberPicker }} />
        <QueryTable.Table
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={goInfoPage}>
              新建试卷
            </Button>
          }
          columns={[
            ...columns,
            {
              title: '操作',
              fixed: 'right',
              width: 30,
              renderType: 'Operation',
              renderProps: (col: IExamInfo) => {
                return {
                  buttons: [
                    {
                      label: '编辑',
                      onClick: () => {
                        console.log('编辑', col);
                      },
                    },
                    {
                      label: '删除',
                      onClick: () => {
                        console.log('删除', col);
                      },
                    },
                  ],
                };
              },
            },
          ]}
          scroll={{ x: 1200 }}
          rowKey="examId"
        />
      </QueryTable.Provider>
    </Card>
  );
}
